import Teams from './TeamsEnum';
import DatePicker from "react-datepicker";
import React, { useState } from 'react';


const NextMatchForm = ({ formDataNextMatch, onInputChange, onSubmit }) => {

  const [startDate, setStartDate] = useState(new Date());

  const handleChange = (date) => {
    setStartDate(date);

    const formattedDate = date.toLocaleDateString('en-GB');

    onInputChange({
      target: { name: 'date', value: formattedDate }
    });
  };

  const isFormValid = () => {
    const requiredFields = [
      'vsTeam',
      'date',
      'matchHours',
      'departureBusHours',
      'departurePlaceBus',
      'arrivalPlaceBus',
      'paymentLink',
    ];
    return requiredFields.every((field) => formDataNextMatch[field]?.trim());
  };

  return (
    <form onSubmit={onSubmit}>

      <div className="mb-3 mt-3">
        <label htmlFor="vsTeam" className="form-label">
          Versus
        </label>

        <select
          className="form-control"
          id="vsTeam"
          name="vsTeam"
          onChange={onInputChange}
          value={formDataNextMatch.vsTeam}>

          <option value="" disabled>
            Seleccionar equipo
          </option>
          {Object.entries(Teams).map(([key, value]) => (
            <option key={key} value={value}>
              {value}
            </option>
          ))}
        </select>
      </div>

      <div className="mb-3 mt-3">
        <label htmlFor="date" className="form-label">
          Fecha
        </label>
        <DatePicker
          selected={startDate}
          onChange={handleChange}
          className="form-control"
          id="date"
          name="date"
          dateFormat="dd-MM-yyyy"
        />
      </div>

      <div className="mb-3">
        <label htmlFor="matchHours" className="form-label">
          Hora Partido
        </label>
        <input
          type="time"
          className="form-control"
          id="matchHours"
          name="matchHours"
          onChange={onInputChange}
          value={formDataNextMatch.matchHours}
        />
      </div>

      <div className="mb-3">
        <label htmlFor="departureBusHours" className="form-label">
          Hora Salida Micro
        </label>
        <input
          type="time"
          className="form-control"
          id="departureBusHours"
          name="departureBusHours"
          onChange={onInputChange}
          value={formDataNextMatch.departureBusHours}
        />
      </div>

      <div className="mb-3">
        <label htmlFor="departurePlaceBus" className="form-label">
          Lugar Salida Micro
        </label>
        <input type="text" className="form-control" id="departurePlaceBus" name="departurePlaceBus" onChange={onInputChange} value={formDataNextMatch.departurePlaceBus} />
      </div>

      <div className="mb-3">
        <label htmlFor="arrivalPlaceBus" className="form-label">
          Lugar de Llegada Micro
        </label>
        <input type="text" className="form-control" id="arrivalPlaceBus" name="arrivalPlaceBus" onChange={onInputChange} value={formDataNextMatch.arrivalPlaceBus} />
      </div>

      <div className="mb-3">
        <label htmlFor="paymentLink" className="form-label">
          Link de Pago (o Alias)
        </label>
        <input
          type="text"
          className="form-control"
          id="paymentLink"
          name="paymentLink"
          onChange={onInputChange}
          value={formDataNextMatch.paymentLink}
        />
        <small id="paymentLinkHint" className="form-text text-muted">
          Ejemplo: ROJO.LAPLATA
        </small>
      </div>

      <button
        type="submit"
        style={{
          backgroundColor: '#C73B33',
          borderColor: '#FF5733',
          color: '#FFF',
        }}
        className="btn"
        disabled={!isFormValid()}>
          
        Crear Próximo Partido
      </button>

    </form>
  );
};


export default NextMatchForm;