import React from 'react';

const NotificationForm = ({ formDataNotification, onInputChange, onSubmit }) => {
  return (
    <form onSubmit={onSubmit}>
      <div className="mb-3 mt-3">
        <label htmlFor="title" className="form-label">
          Título
        </label>
        <input type="text" className="form-control" id="title" name="title" onChange={onInputChange} value={formDataNotification.title} />
      </div>

      <div className="mb-3">
        <label htmlFor="body" className="form-label">
          Mensaje
        </label>
        <input type="text" className="form-control" id="body" name="body" onChange={onInputChange} value={formDataNotification.body} />
      </div>

      <button type="submit" className="btn btn-primary" style={{ backgroundColor: '#C73B33', borderColor: '#FF5733', color: '#FFF' }}>
        Enviar
      </button>
    </form>
  );
};

export default NotificationForm;
