import api from '../../api';
import React, { useState, useEffect } from 'react'
import PostForm from './PostForm';
import PostTable from './PostTable';

const Announcements = () => { //TODO rename for Post
  const [posts, setPosts] = useState([]);

  const [formDataPost, setFormDataPost] = useState({
    name: '',
    description: '',
    date: '',
    link: '',
    is_new: false
  });

  const fetchPosts = async () => {
    const response = await api.get('/posts/');
    setPosts(response.data)
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const handleInputChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setFormDataPost({
      ...formDataPost,
      [event.target.name]: value,
    });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); 
    const year = today.getFullYear();
    const formattedDate = `${day}/${month}/${year}`; 
  
    const updatedFormData = { 
      ...formDataPost, 
      date: formattedDate 
    };
  
    await api.post('/posts/', updatedFormData);
    fetchPosts();
  
    setFormDataPost({
      name: '',
      description: '',
      date: '',
      link: '',
      is_new: false
    });
  };
  

  return (
    <div className="container">
      <PostForm formDataPost={formDataPost} onInputChange={handleInputChange} onSubmit={handleFormSubmit} />
      <PostTable posts={posts} />
    </div>
  );
};

export default Announcements;
