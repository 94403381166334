import React, { useState, useEffect } from 'react';
import Teams from './TeamsEnum';
import DatePicker from "react-datepicker";

const NextMatchTable = ({ nextMatches, onDelete, onEdit, onInputChange }) => {
  const [sortedMatches, setSortedMatches] = useState([]);

  const [showDialog, setShowDialog] = useState(false);
  const [selectedMatchId, setSelectedMatchId] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [startDate, setStartDate] = useState(new Date());

  // Sort matches by date whenever nextMatches changes
  useEffect(() => {
    const sorted = [...nextMatches].sort((a, b) => new Date(a.date) - new Date(b.date));
    setSortedMatches(sorted);
  }, [nextMatches]);

  // Form data for editing
  const [formData, setFormData] = useState({
    id: '',
    date: '',
    matchHours: '',
    departureBusHours: '',
    departurePlaceBus: '',
    arrivalPlaceBus: '',
    paymentLink: '',
    vsTeam: '',
  });

  const handleDeleteClick = (id) => {
    setSelectedMatchId(id);
    setShowDialog(true);
  };

  const confirmDelete = () => {
    onDelete(selectedMatchId);
    setShowDialog(false);
  };

  const cancelDelete = () => {
    setShowDialog(false);
  };

  const handleEditClick = (match) => {
    setSelectedMatch(match);
    setFormData({ ...match });
    setEditMode(true);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    await onEdit(formData);
    setEditMode(false);
    setSelectedMatch(null);
  };

  return (
    <>
      {/* Table */}
      {!editMode ? (
        <table className="table table-striped table-bordered table-hover">
          <thead>
            <tr>
              <th>Versus</th>
              <th>Fecha</th>
              <th>Hora</th>
              <th>Hora de salida Micro</th>
              <th>Lugar de salida Micro</th>
              <th>Lugar de llegada Micro</th>
              <th>Link de pago</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {nextMatches.map((match) => (
              <tr key={match.id}>
                <td>{match.vsTeam}</td>
                <td>{match.date}</td>
                <td>{match.matchHours}</td>
                <td>{match.departureBusHours}</td>
                <td>{match.departurePlaceBus}</td>
                <td>{match.arrivalPlaceBus}</td>
                <td>{match.paymentLink}</td>
                <td>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <button
                      onClick={() => handleEditClick(match)}
                      className="btn btn-warning"
                    >
                      Editar
                    </button>
                    <button
                      onClick={() => handleDeleteClick(match.id)}
                      className="btn btn-danger"
                    >
                      Eliminar
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (


        /* ***---*** Edit form ***---*** */

        <div className="edit-form">
          <h3>Editar Partido</h3>
          <form onSubmit={handleEditSubmit}>

            <div className="form-group">
              <label htmlFor="vsTeam" className="form-label">
                Versus
              </label>
              <select
                className="form-control"
                id="vsTeam"
                name="vsTeam"
                onChange={handleInputChange}
                value={formData.vsTeam}>

                <option value="" disabled>
                  Seleccionar equipo
                </option>
                {Object.entries(Teams).map(([key, value]) => (
                  <option key={key} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ height: '20px' }}></div>

            <div className="form-group">
              <label htmlFor="date" className="form-label">Fecha</label>
              <DatePicker
                selected={formData.date ? new Date(formData.date) : null}
                className="form-control"
                id="date"
                name="date"
                dateFormat="dd-MM-yyyy"
                onChange={(date) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    date: date.toISOString().split('T')[0],
                  }));
                }}
              />
            </div>

            <div style={{ height: '20px' }}></div>

            <div className="mb-3">
              <label htmlFor="matchHours" className="form-label">
                Hora Partido
              </label>
              <input
                type="time"
                className="form-control"
                id="matchHours"
                name="matchHours"
                onChange={(event) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    matchHours: event.target.value,
                  }));
                }}
                value={formData.matchHours}
              />
            </div>


            <div style={{ height: '20px' }}></div>

            <div className="mb-3">
              <label htmlFor="departureBusHours" className="form-label">
                Hora Salida Micro
              </label>
              <input
                type="time"
                className="form-control"
                id="departureBusHours"
                name="departureBusHours"
                onChange={(event) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    departureBusHours: event.target.value,
                  }));
                }}
                value={formData.departureBusHours}
              />
            </div>

            <div style={{ height: '20px' }}></div>

            <div className="form-group">
              <label>Lugar Salida Micro</label>
              <input
                type="text"
                name="departurePlaceBus"
                value={formData.departurePlaceBus}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>

            <div style={{ height: '20px' }}></div>

            <div className="form-group">
              <label>Lugar Llegada Micro</label>
              <input
                type="text"
                name="arrivalPlaceBus"
                value={formData.arrivalPlaceBus}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>

            <div style={{ height: '20px' }}></div>

            <div className="form-group">
              <label>Link de Pago (o Alias)</label>
              <input
                type="text"
                name="paymentLink"
                value={formData.paymentLink}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>

            <div style={{ height: '20px' }}></div>

            <button
              type="submit"
              style={{ backgroundColor: '#C73B33', borderColor: '#FF5733', color: '#FFF' }}
              className="btn">
              Guardar Cambios
            </button>


            <button
              type="button"
              className="btn btn-secondary ms-2"
              onClick={() => setEditMode(false)}>
              Cancelar
            </button>

            <div style={{ height: '80px' }}></div>

          </form>
        </div>
      )}

      {/* Dialog box for delete confirmation */}
      {showDialog && (
        <div className="modal" style={modalStyles}>
          <div className="modal-content" style={modalContentStyles}>
            <h4>Estas seguro?</h4>
            <p>Una vez borrado no se puede recuperar</p>
            <div className="modal-footer">
              <button onClick={confirmDelete} className="btn btn-danger">
                OK
              </button>
              <button onClick={cancelDelete} className="btn btn-secondary">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};



// Modal styles for delete confirmation dialog
const modalStyles = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0,0,0,0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const modalContentStyles = {
  backgroundColor: 'white',
  padding: '50px',
  borderRadius: '5px',
  textAlign: 'center',
};

export default NextMatchTable;
