import { Link } from 'react-router-dom';
import api from '../../api';
import NotificationTable from './NotificationTable';
import NotificationForm from './NotificationForm';
import React, { useState, useEffect } from 'react'


const Notifications = () => {
 
    const [notifications, setNotifications] = useState([]);

    const [formDataNotification, setFormDataNotification] = useState({
        body: '',
        date: '',
        title: ''
    })

    const fetchNotifications = async () => {
        const response = await api.get('/notifications')
        setNotifications(response.data)
    };

    useEffect(() => {
        fetchNotifications();
      }, []);

    const handleInputChange = (event) => {
      const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
      setFormDataNotification({
        ...formDataNotification,
        [event.target.name]: value,
      });
    };

    const handleFormSubmit = async (event) => {
      event.preventDefault();
      await api.post('/send_notification/', formDataNotification);
      fetchNotifications();
      setFormDataNotification({
        body: '',
        date: '',
        title: ''
      });
    };


    return (
        <div className="container">
        <div style={{ height: '20px' }}></div>
        <NotificationForm formDataNotification={formDataNotification} onInputChange={handleInputChange} onSubmit={handleFormSubmit} />
        <div style={{ height: '20px' }}></div>
        <NotificationTable notifications={notifications} />
      </div>
    );
};

export default Notifications;
