const Teams = {
    ARGENTINOS_JRS: "Argentinos Jrs.",
    ATLETICO_TUCUMAN: "Atlético Tucumán",
    BANFIELD: "Banfield",
    BARRACAS_CENTRAL: "Barracas Central",
    BELGRANO: "Belgrano Córdoba",
    BOCA_JRS: "Boca Jrs",
    CENTRAL_CORDOBA: "Central Córdoba",
    DEFENSA_Y_JUSTICIA: "Defensa y Justicia",
    ESTUDIANTES_LP: "Estudiantes LP",
    GIMNASIA_LP: "Gimnasia LP",
    GODOY_CRUZ: "Godoy Cruz",
    HURACAN: "Huracán",
    INDEPENDIENTE_RIVADAVIA: "Independiente Riv.",
    INSTITUTO_CBA: "Instituto Cba",
    LANUS: "Lanus",
    NEWELLS_OLD_BOYS: "Newell's Old Boys",
    PLATENSE: "Platense",
    RACING_CLUB: "Rasin",
    RIESTRA: "Riestra",
    RIVER_PLATE: "River Plate",
    ROSARIO_CENTRAL: "Rosario Central",
    SAN_LORENZO: "San Lorenzo",
    SARMIENTO: "Sarmiento",
    TALLERES: "Talleres",
    TIGRE: "Tigre",
    UNION: "Unión",
    VELEZ: "Vélez Sarsfield",
  };

export default Teams;