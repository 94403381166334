import React from 'react';

const PostTable = ({ posts }) => {
  return (
    <table className="table table-striped table-bordered table-hover">
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Descripción</th>
          <th>Link</th>
          <th>Fecha</th>
        </tr>
      </thead>
      <tbody>
        {posts.map((post) => (
          <tr key={post.id}>
            <td>{post.name}</td>
            <td>{post.description}</td>
            <td>{post.link}</td>
            <td>{post.date}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PostTable;
