import React from 'react';

const NotificationTable = ({ notifications }) => {
  return (
    
    <table className="table table-striped table-bordered table-hover">
      <thead>
        <tr>
          <th>Titulo</th>
          <th>Mensaje</th>
          <th>Fecha</th>
        </tr>
      </thead>
      <tbody>
        {notifications.map((notification) => (
          <tr key={notification.id}>
            <td>{notification.title}</td>
            <td>{notification.body}</td>
            <td>{notification.date}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default NotificationTable;