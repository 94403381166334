import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import NextMatch from './tabs/NextMatch/NextMatch';
import Announcements from './tabs/Announcements/Announcements';
import Notifications from './tabs/Notifications/Notifications';
import Navbar from './navbar/Navbar';
import "react-datepicker/dist/react-datepicker.css";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showLoginDialog, setShowLoginDialog] = useState(true);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {


    if (username === 'admin' && password === 'peni@R0j@laplata') {

      setIsAuthenticated(true);
      setShowLoginDialog(false);
    } else {
      alert('Incorrect username or password');
    }
  };

  return (
    <Router> {}
      {showLoginDialog && (
        <div style={modalStyle}>
          <div style={modalContentStyle}>
            
            <h3>Peña Roja La Plata ADMIN</h3>
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              style={inputStyle}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={inputStyle}
            />
          
            <button onClick={handleLogin} style={buttonStyle}>Login</button>
          </div>
        </div>
      )}
      
      {isAuthenticated && (
        <>
          <Navbar />
          <Routes>
            <Route path="/next-match" element={<NextMatch />} />
            <Route path="/announcements" element={<Announcements />} />
            <Route path="/notifications" element={<Notifications />} />
          </Routes>
        </>
      )}
    </Router>
  );
};

const modalStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const modalContentStyle = {
  backgroundColor: 'white',
  padding: '20px',
  borderRadius: '8px',
  textAlign: 'center',
};

const inputStyle = {
  marginBottom: '10px',
  padding: '8px',
  width: '100%',
  boxSizing: 'border-box',
  borderRadius: '4px',
};

const buttonStyle = {
  padding: '10px 20px',
  backgroundColor: '#C73B33',
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
};

export default App;
