import React from 'react';

const PostForm = ({ formDataPost, onInputChange, onSubmit }) => {


  const isFormValid = () => {
    const requiredFields = [
      'name',
      'description',
      'link'
    ];
    return requiredFields.every((field) => formDataPost[field]?.trim());
  };



  return (
    <form onSubmit={onSubmit}>
      <div className="mb-3 mt-3">
        <label htmlFor="name" className="form-label">
          Título
        </label>
        <input type="text" className="form-control" id="name" name="name" onChange={onInputChange} value={formDataPost.name} />
      </div>

      <div className="mb-3">
        <label htmlFor="description" className="form-label">
          Descripción
        </label>
        <input type="text" className="form-control" id="description" name="description" onChange={onInputChange} value={formDataPost.description} />
      </div>

      <div className="mb-3">
        <label htmlFor="link" className="form-label">
          Link
        </label>
        <input type="text" className="form-control" id="link" name="link" onChange={onInputChange} value={formDataPost.link} />
        <small id="paymentLinkHint" className="form-text text-muted">
          Ejemplo: https://cai.boleteriavip.com.ar/category/description/1/futbol/
        </small>
      </div>

      <div style={{ height: '20px' }}></div>

      <button
        type="submit"
        style={{
          backgroundColor: '#C73B33',
          borderColor: '#FF5733',
          color: '#FFF',
        }}
        className="btn"
        disabled={!isFormValid()}>
          
        Crear Anuncio 
      </button>

      <div style={{ height: '20px' }}></div>

    </form>

  );
};

export default PostForm;
