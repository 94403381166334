import React from 'react';
import banner_filial_lp from '../assets/banner_filial_lp.png';
import { Link } from 'react-router-dom';
import '../css/Navbar.css';

const Navbar = () => {
  return (
    <>
      <nav className="navbar">
        <div className="container-fluid d-flex justify-content-between align-items-center">
          <Link className="navbar-brand" to="/">
            <img
              src={banner_filial_lp}
              alt="Logo"
              className="d-inline-block align-middle logo"
            />
          </Link>

          <div className="nav-tabs">
            <Link className="nav-link" to="/next-match">
              Próximo Partido
            </Link>
            <Link className="nav-link" to="/announcements">
              Anuncios
            </Link>
            <Link className="nav-link" to="/notifications">
              Notificaciones
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
